export default {
  mounted() {
    setTimeout(() => {
      this.resizeTextArea()
    }, 0)
  },
  methods: {
    resizeTextArea() {
      if (this.$refs.textarea && this.$refs.textarea.style) {
        this.$refs.textarea.style.height = 'auto'
        this.$refs.textarea.style.height = this.$refs.textarea.scrollHeight + 'px'
      }
    }
  }
}