import { createBatch, createId, getFirestoreDoc, getFirestoreDocs, firestoreDeleteField, firebaseArrayRemove } from '@/firebase/firestore'
import { tracePerformance } from '@/firebase/performance'
import { logAnalyticsEvent } from '@/firebase/analytics'
import { getUid } from '@/firebase/auth'
import localizeFilter from '@/filters/localize.filter'
import Currencies from '@/libraries/currencies'
import router from '../router'
import appCardsColors from '@/libraries/app-cards-colors'
import useNotifications from '@/composables/useNotifications'
import { checkValues } from '@/helpers/check-values'

export default {
  state: {
    accounts: {},
    accountsReset: 0,
    accountsFilters: {
      section: null,
      bankId: null,
      accountId: null,
      cardId: null,
      currencyCode: null,
      expiringCards: false,
      expiredCards: false,
      onlyDefault: false,
      searchStr: ''
    },
    accountsShow: {
      add: false,
      filters: false,
      deleteAccount: null,
      editBank: null,
      cards: null
    },
    showArchivedAccounts: false,
    accountsSort: {
      field: 'Account',
      direction: 'asc'
    }
  },
  mutations: {
    setAccount(state, { accountId, data }) {
      if (accountId && data) {
        state.accounts[accountId] = data
      }
    },
    updateAccount(state, { accountId, data }) {
      if (accountId && data) {
        for (const key of Object.keys(data)) {
          state.accounts[accountId][key] = data[key]
        }
      }
    },
    deleteAccountFromStore(state, accountId) {
      if (accountId && state.accounts[accountId]) {
        delete state.accounts[accountId]
      }
    },
    resetAccounts(state) {
      state.accountsReset = Date.now()
    },
    setAccountsFilters(state, filters) {
      state.accountsFilters = filters
    },
    setAccountsFiltersSection(state, section) {
      state.accountsFilters.section = section
    },
    setAccountsFiltersOnlyDefault(state, value) {
      state.accountsFilters.onlyDefault = value
    },
    setAccountsFiltersSearchStr(state, value) {
      state.accountsFilters.searchStr = value
    },
    setAccountsShowField(state, show) {
      if (show && Object.keys(show).length) {
        for (const field of Object.keys(show)) {
          state.accountsShow[field] = show[field]
        }
      }
    },
    setAccountsShowArchived(state, value) {
      state.showArchivedAccounts = value
    },
    setAccountsSortDirection(state, direction) {
      state.accountsSort.direction = direction
    },
    setAccountsSortField(state, field) {
      state.accountsSort.field = field
    },
    clearAccountsFilters(state) {
      state.accountsFilters = {
        section: null,
        bankId: null,
        accountId: null,
        cardId: null,
        currencyCode: null,
        expiringCards: false,
        expiredCards: false,
        onlyDefault: false,
        searchStr: ''
      }
    },
    clearAccountsOneFilter(state, filter) {
      if (filter) {
        const filters = state.accountsFilters

        if (filter === 'Account' || filter === 'Bank') {
          filters.bankId = null
          filters.accountId = null
          filters.cardId = null
        } else if (filter === 'Currency') {
          filters.currencyCode = null
        } else if (filter === 'Cards') {
          filters.expiringCards = false
          filters.expiredCards = false
        }

        state.accountsFilters = filters
      }
    },
    clearAccountsSort(state) {
      state.accountsSort = {
        field: 'Account',
        direction: 'asc'
      }
    },
    clearAccounts(state) {
      state.accounts = {}
    },
    clearAccountsShow(state) {
      state.accountsShow = {
        add: false,
        filters: false,
        deleteAccount: null,
        editBank: null,
        cards: null
      }
    },
    clearInfo(state) {
      state.accounts = {}
      state.accountsFilters = {
        section: null,
        bankId: null,
        accountId: null,
        cardId: null,
        currencyCode: null,
        expiringCards: false,
        expiredCards: false,
        onlyDefault: false,
        searchStr: ''
      }
      state.accountsShow = {
        add: false,
        filters: false,
        deleteAccount: null,
        editBank: null,
        cards: null
      }
      state.showArchivedAccounts = false
      state.accountsSort = {
        field: 'Account',
        direction: 'asc'
      }
    }
  },
  actions: {
    async fetchAccounts({ commit, dispatch, getters }, active) {
      if (getters.lessonStep) { return }
      if (!active && getters.loadedAll('accountsArchived')) { return }

      const t = tracePerformance('fetchAccounts')
      t.start()

      const { toastify } = useNotifications()
      let toastId

      try {
        if (!active) {
          toastId = toastify.warning(localizeFilter('LoadingArchivedAccounts'), { timeout: null })
        }

        const request = {
          wheres: [
            ['owner', '==', getUid()],
            ['active', '==', active]
          ]
        }

        const accounts = await getFirestoreDocs({
          collectionName: 'accounts',
          ...request
        })

        if (accounts) {
          for (const account of accounts) {
            await dispatch('fetchDataForAccount', { accountId: account.id, data: account.data })
            await commit('setAccount', { accountId: account.id, data: account.data })
          }

          if (active === true) {
            await commit('setLoadedAll', { field: 'accountsActive', value: true })
          } else if (active === false) {
            await commit('setLoadedAll', { field: 'accountsArchived', value: true })
          }

          commit('resetAccounts')
        }

        if (!active) {
          toastify.replace(toastId, localizeFilter('ArchivedAccountsLoaded'), 'info')
        }
        return true
      } catch (e) {
        dispatch('saveErrorInfo', { error: e, location: 'fetchAccounts', params: { active }, toastId })
        return false
      } finally {
        t.stop()
      }
    },
    async fetchAccount({ commit, dispatch, getters }, accountId) {
      if (getters.lessonStep) { return }

      if (!accountId) {
        commit('setError', localizeFilter('Error'))
        return false
      }

      const t = tracePerformance('fetchAccount')
      t.start()

      try {
        const data = await getFirestoreDoc('accounts', accountId)

        if (data) {
          await dispatch('fetchDataForAccount', { accountId, data })
          await commit('setAccount', { accountId, data })
          commit('resetAccounts')
          return true
        }
      } catch (e) {
        dispatch('saveErrorInfo', { error: e, location: 'fetchAccount', params: { accountId } })
        return false
      } finally {
        t.stop()
      }
    },
    async fetchDataForAccount({ commit, dispatch, getters }, { accountId, data }) {
      if (getters.lessonStep) { return }

      if (!accountId || !data) {
        commit('setError', localizeFilter('Error'))
        return false
      }

      if (data.bankId && !getters.banks[data.bankId] && getters.getLimitNumber('maxBanks')) {
        await dispatch('fetchBank', data.bankId)
      }

      if (getters.getLimitNumber('maxCards') && !getters.loadedAll('cardsActive') && !getters.loadedAll('cardsArchived')) {
        await dispatch('fetchCards', { active: true, accountId })
      }
    },
    async canDeleteAccount({ commit, dispatch, getters }, accountId) {
      if (getters.lessonStep) { return }

      if (!accountId) {
        commit('setError', localizeFilter('Error'))
        return false
      }

      let canDelete = true

      const t = tracePerformance('canDeleteAccount')
      t.start()

      try {
        commit('setProcessing', true)

        if (getters.transactionsByAccountIds.includes(accountId)) {
          canDelete = false
          return canDelete
        }

        const request = {
          wheres: [
            ['owner', '==', getUid()],
            ['accountId', '==', accountId]
          ],
          maxLimit: 1
        }

        const transactions = await getFirestoreDocs({
          collectionName: 'transactions',
          ...request
        })

        if (transactions) { canDelete = false }

        return canDelete
      } catch (e) {
        dispatch('saveErrorInfo', { error: e, location: 'canDeleteAccount', params: { accountId } })
        return false
      } finally {
        commit('setProcessing', false)
        t.stop()
      }
    },
    async createAccount({ commit, dispatch, getters }, { name = '', currencyCode = 'USD', sum = 0 }) {
      if (getters.lessonStep || !getters.online) { return }

      if (name) { name = name.trim() }

      if (!name || name.length > checkValues('accountName', 'max') || !currencyCode) {
        commit('setError', localizeFilter('Error'))
        return false
      }

      if (!getters.getAvailableLimitNumber('accountsReset', 'accounts', 'maxAccounts') || !getters.getLimitNumber('maxAccounts') || (+getters.userStats('accounts') >= +getters.getLimitNumber('maxAccounts'))) {
        commit('setAppShowField', { payWall: true })
        return
      }

      const { toastify } = useNotifications()

      const t = tracePerformance('createAccount')
      t.start()

      const syncTimestamp = new Date()
      let toastId

      try {
        toastId = toastify.warning(localizeFilter('Saving') + '...', { timeout: null })

        const data = {
          name,
          currencyCode,
          sum: +sum,
          active: true,
          timestamp: syncTimestamp,
          color: 'white',
          owner: getUid()
        }

        commit('setProcessing', true)

        const accountId = createId('accounts')

        const batchArray = await createBatch([
          {
            timestamp: syncTimestamp,
            type: 'set',
            place: 'accounts',
            id: accountId,
            data,
            updateStats: 1
          }
        ])

        await dispatch('subscribeToLogs', syncTimestamp)

        for (const batch of batchArray) { await batch.commit() }

        await dispatch('accountAdded', { accountId, data })
        toastify.replace(toastId, localizeFilter('Saved'), 'success')
      } catch (e) {
        dispatch('saveErrorInfo', { error: e, location: 'createAccount', params: { name, currencyCode, sum }, toastId })
        return false
      } finally {
        commit('setProcessing', false)
        t.stop()
      }
    },
    async accountAdded({ commit }, { accountId, data }) {
      if (!accountId || !data) {
        commit('setError', localizeFilter('Error'))
        return false
      }

      await commit('setAccount', { accountId, data })
      await commit('increaseTotalNumberOf', { field: 'accounts', number: 1 })

      commit('resetAccounts')
    },
    async showCreateAccountClicked({ commit, dispatch, getters }, { from = null }) {
      if (!getters.getLimitNumber('maxAccounts') || !getters.getAvailableLimitNumber('accountsReset', 'accounts', 'maxAccounts') || (+getters.userStats('accounts') >= +getters.getLimitNumber('maxAccounts'))) {
        commit('setAppShowField', { payWall: true })
        return
      }

      await dispatch('setCloseAllMenus')
      setTimeout(() => {
        commit('setAccountsShowField', { add: true })
      }, 0)

      logAnalyticsEvent('showCreateAccountClicked', { from: from })
    },
    showStarAccountClicked({ commit }, { show, from }) {
      commit('setAccountsFiltersOnlyDefault', show)
      logAnalyticsEvent('showStarAccountClicked', { from })
    },
    showFilterAccountsButtonClicked({ commit }, { section, value }) {
      commit('setAccountsFiltersSection', section)
      commit('setAccountsShowField', { filters: value })
    },
    clearAccountsAllFilters({ commit }) {
      commit('setAccountsFiltersSection', null)
      commit('clearAccountsFilters')
      commit('setAccountsShowField', { filters: false })
    },
    showArchivedAccountsClicked({ commit, dispatch, getters }) {
      const shown = getters.showArchivedAccounts
      commit('setAccountsShowArchived', !shown)
      if (!shown) { dispatch('fetchAccounts', false) }
    },
    openMoneyBoxesClicked({ commit, getters }) {
      if (!getters.getLimitNumber('maxMoneyBoxes')) {
        commit('setAppShowField', { payWall: true })
        return
      }

      router.push({ name: 'MoneyBoxes' }).catch(() => { })
    },
    async editAccountOneField({ commit, dispatch, getters }, { accountId, data }) {
      if (getters.lessonStep || !getters.online) { return }

      const { toastify } = useNotifications()

      if (getters.processing) {
        toastify.error(localizeFilter('Wait') + '...')
        return
      }

      if (!accountId || !data || (data.name && !data.name.trim())) {
        commit('setError', localizeFilter('Error'))
        return false
      }

      if (!getters.getLimitNumber('maxAccounts') || (+getters.userStats('accounts') > +getters.getLimitNumber('maxAccounts'))) {
        commit('setAppShowField', { payWall: true })
        return
      }

      const account = getters.accounts[accountId]
      if (!account) {
        commit('setError', localizeFilter('Error'))
        return false
      }

      let logField

      if (data.color !== undefined) {
        logField = 'color'

        if (!getters.canUseLimit('accountColor', true)) {
          commit('setAppShowField', { payWall: true })
          return
        }

        if (!appCardsColors[data.color]) {
          commit('setError', localizeFilter('Error'))
          return false
        }

        if (Object.keys(data).length === 1) {
          if (account.color === data.color) { return true }
        }
      } else if (data.bankId !== undefined) {
        logField = 'bankId'

        if (!getters.getLimitNumber('maxBanks')) {
          commit('setAppShowField', { payWall: true })
          return
        }

        if (data.bankId && !getters.banks[data.bankId]) {
          commit('setError', localizeFilter('Error'))
          return false
        }
      } else if (data.creditLimit !== undefined) {
        logField = 'creditLimit'

        if (!getters.canUseLimit('creditLimit', true)) {
          commit('setAppShowField', { payWall: true })
          return
        }

        if (!getters.accounts[accountId].active) {
          commit('setError', localizeFilter('Error'))
          return false
        }

        if (+account.creditLimit === +data.creditLimit) { return true }
      }

      const t = tracePerformance('editAccountOneField')
      t.start()

      const syncTimestamp = new Date()
      let toastId

      try {
        commit('setProcessing', true)

        const newAccountDataFB = {}
        const newAccountDataStore = {}

        if (data.name && data.name !== account.name) {
          let newAccountName = data.name.trim()
          if (!newAccountName.length > checkValues('accountName', 'max')) { newAccountName = newAccountName.substring(0, checkValues('accountName', 'max')) }

          newAccountDataFB.name = newAccountName
          newAccountDataStore.name = newAccountName
        }

        if (data.creditLimit !== undefined && data.creditLimit !== account.creditLimit) {
          newAccountDataFB.creditLimit = +data.creditLimit
          newAccountDataStore.creditLimit = +data.creditLimit
        }

        if (data.bankId !== undefined && data.bankId !== account.bankId) {
          if (data.bankId) {
            newAccountDataFB.bankId = data.bankId
            newAccountDataStore.bankId = data.bankId
          } else {
            newAccountDataFB.bankId = firestoreDeleteField
            newAccountDataStore.bankId = null
          }
        }

        if (data.color !== undefined && data.color !== account.color) {
          newAccountDataFB.color = data.color
          newAccountDataStore.color = data.color
        }

        if (!Object.keys(newAccountDataFB).length) { return true }

        toastId = toastify.warning(localizeFilter('Saving') + '...', { timeout: null })

        const batchArray = await createBatch([
          {
            timestamp: syncTimestamp,
            type: 'update',
            place: 'accounts',
            id: accountId,
            data: newAccountDataFB,
            logDataInfo: newAccountDataStore
          }
        ])

        await dispatch('subscribeToLogs', syncTimestamp)

        for (const batch of batchArray) { await batch.commit() }

        await dispatch('accountEdited', { accountId, data: newAccountDataStore })
        toastify.replace(toastId, localizeFilter('Saved'), 'success')
        if (logField) { logAnalyticsEvent('accountEdited', { field: logField }) }
        return true
      } catch (e) {
        dispatch('saveErrorInfo', { error: e, location: 'editAccountOneField', params: { accountId, data }, toastId })
        return false
      } finally {
        commit('setProcessing', false)
        t.stop()
      }
    },
    async setAccountActive({ commit, dispatch, getters }, accountId) {
      if (getters.lessonStep || !getters.online) { return }

      const { toastify } = useNotifications()

      if (getters.processing) {
        toastify.error(localizeFilter('Wait') + '...')
        return
      }

      if (!accountId || !getters.accounts[accountId]) {
        commit('setError', localizeFilter('Error'))
        return false
      }

      const accountIsActive = getters.accounts[accountId].active

      if (!getters.getLimitNumber('maxAccounts')
        || (+getters.userStats('accounts') > +getters.getLimitNumber('maxAccounts'))
        || (!accountIsActive && (Object.keys(getters.accounts).length >= getters.getLimitNumber('maxAccounts')))
      ) {
        commit('setAppShowField', { payWall: true })
        return
      }

      const t = tracePerformance('setAccountActive')
      t.start()

      let toastId

      const syncTimestamp = new Date()

      const data = { active: !accountIsActive }

      try {
        commit('setProcessing', true)
        toastId = toastify.warning(localizeFilter('Saving') + '...', { timeout: null })

        const batchData = [
          {
            timestamp: syncTimestamp,
            type: 'update',
            place: 'accounts',
            id: accountId,
            data
          }
        ]

        if (accountIsActive) {
          const request = {
            wheres: [
              ['accountId', '==', accountId],
              ['active', '==', true],
              ['owner', '==', getUid()]
            ]
          }

          const cards = await getFirestoreDocs({
            collectionName: 'cards',
            ...request
          })

          if (cards) {
            for (const card of cards) {
              batchData.push({
                timestamp: syncTimestamp,
                type: 'update',
                place: 'cards',
                id: card.id,
                data: {
                  active: false
                }
              })
            }
          }
        }

        const newDefaultAccountData = {
          accountId: null,
          cardId: null
        }

        if (accountIsActive && getters.defaultAccount.accountId === accountId) {
          batchData.push({
            timestamp: syncTimestamp,
            type: 'set',
            place: 'userDefaultAccount',
            id: getUid(),
            data: newDefaultAccountData
          })
        }

        const batchArray = await createBatch(batchData)

        await dispatch('subscribeToLogs', syncTimestamp)

        for (const batch of batchArray) { await batch.commit() }

        if (accountIsActive && getters.defaultAccount.accountId === accountId) {
          await dispatch('defaultAccountFetched', newDefaultAccountData)
        }

        await dispatch('accountEdited', { accountId, data })

        toastify.replace(toastId, localizeFilter('Saved'), 'success')
        logAnalyticsEvent('accountArchived', { set: !accountIsActive })
      } catch (e) {
        dispatch('saveErrorInfo', { error: e, location: 'setAccountActive', params: { accountId }, toastId })
        return false
      } finally {
        commit('setProcessing', false)
        t.stop()
      }
    },
    async accountEdited({ commit }, { accountId, data }) {
      if (!accountId || !data) {
        commit('setError', localizeFilter('Error'))
        return false
      }

      if (data.active === false) {
        await commit('setAccountCardsInactive', accountId)
        commit('resetCards')
      }

      await commit('updateAccount', { accountId, data })
      commit('resetAccounts')
    },
    async deleteAccount({ commit, dispatch, getters }, { accountId = null }) {
      if (getters.lessonStep || !getters.online) { return }

      const { toastify } = useNotifications()

      if (getters.processing) {
        toastify.error(localizeFilter('Wait') + '...')
        return
      }

      if (!accountId || !getters.accounts[accountId]) {
        commit('setError', localizeFilter('Error'))
        return false
      }

      const t = tracePerformance('deleteAccount')
      t.start()

      const syncTimestamp = new Date()
      let toastId

      try {
        commit('setProcessing', true)
        toastId = toastify.warning(localizeFilter('Checking') + '...', { timeout: null })

        const canDeleteAccount = await dispatch('canDeleteAccount', accountId)
        if (!canDeleteAccount) {
          toastify.error(localizeFilter('FirstDeleteAccountTransactions'), { remove: [toastId] })
          return
        }

        toastId = toastify.warning(localizeFilter('Deleting') + '...', { remove: [toastId], timeout: null })

        const batchData = [
          {
            timestamp: syncTimestamp,
            type: 'delete',
            place: 'accounts',
            id: accountId,
            updateStats: -1
          }
        ]

        const requestCards = {
          wheres: [
            ['accountId', '==', accountId],
            ['owner', '==', getUid()]
          ]
        }

        const cards = await getFirestoreDocs({
          collectionName: 'cards',
          ...requestCards
        })

        if (cards) {
          for (const card of cards) {
            batchData.push({
              timestamp: syncTimestamp,
              type: 'delete',
              place: 'cards',
              id: card.id,
              updateStats: -1
            })
          }
        }

        const requestMoneyBoxes = {
          wheres: [
            ['owner', '==', getUid()],
            ['accounts', 'array-contains', accountId]
          ]
        }

        const moneyBoxes = await getFirestoreDocs({
          collectionName: 'moneyBoxes',
          ...requestMoneyBoxes
        })

        if (moneyBoxes) {
          for (const moneyBox of moneyBoxes) {
            batchData.push({
              timestamp: syncTimestamp,
              type: 'update',
              place: 'moneyBoxes',
              id: moneyBox.id,
              data: {
                accounts: firebaseArrayRemove(accountId)
              }
            })
          }
        }

        const newDefaultAccountData = {
          accountId: null,
          cardId: null
        }

        if (getters.defaultAccount.accountId && getters.defaultAccount.accountId === accountId) {
          batchData.push({
            timestamp: syncTimestamp,
            type: 'set',
            place: 'userDefaultAccount',
            id: getUid(),
            data: newDefaultAccountData
          })
        }

        const batchArray = await createBatch(batchData)

        await dispatch('subscribeToLogs', syncTimestamp)

        for (const batch of batchArray) { await batch.commit() }

        if (getters.defaultAccount.accountId && getters.defaultAccount.accountId === accountId) {
          await dispatch('defaultAccountFetched', newDefaultAccountData)
        }

        await dispatch('accountDeleted', accountId)

        toastify.replace(toastId, localizeFilter('AccountDeleted'), 'success')
        logAnalyticsEvent('accountDeleted')
      } catch (e) {
        dispatch('saveErrorInfo', { error: e, location: 'deleteAccount', params: { accountId }, toastId })
        return false
      } finally {
        commit('setProcessing', false)
        t.stop()
      }
    },
    async accountDeleted({ commit }, accountId) {
      if (!accountId) {
        commit('setError', localizeFilter('Error'))
        return false
      }

      await commit('deleteAllCardsOfAccountFromStore', accountId)
      commit('resetCards')

      await commit('deleteAccountFromAllMoneyBoxes', accountId)
      commit('resetMoneyBoxes')

      await commit('deleteAccountFromStore', accountId)
      await commit('increaseTotalNumberOf', { field: 'accounts', number: -1 })
      commit('resetAccounts')
    },
    setAccountsFiltersAction({ commit }, filters) {
      commit('setAccountsFilters', filters)
      logAnalyticsEvent('accountsFeltered', { ...filters })
    }
  },
  getters: {
    accounts: s => s.accounts,
    filteredAccounts: (s, getters) => {
      if (getters.accountsReset) {
        //
      }

      let accountsIds = Object.keys(s.accounts)

      if (accountsIds.length) {
        const cardContainsSearchStr = (accountId) => {
          if (!accountId) { return false }

          let cardsIds = Object.keys(getters.cards)

          if (cardsIds.length) {
            cardsIds = cardsIds.filter(cardId => {
              const card = getters.cards[cardId]
              return ((card.accountId === accountId) && card.name.toLowerCase().includes(s.accountsFilters.searchStr))
            })
          }

          if (cardsIds.length) { return true }
          return false
        }

        const currencyNameContainsSearchStr = (currencyObj) => {
          if (!currencyObj) { return false }

          for (const languageId of Object.keys(currencyObj)) {
            if (currencyObj[languageId] && currencyObj[languageId].toLowerCase().includes(s.accountsFilters.searchStr.toLowerCase())) { return true }
          }
          return false
        }

        accountsIds = accountsIds.filter(accountId => {
          const account = s.accounts[accountId]

          return (
            (!s.accountsFilters.onlyDefault || (getters.defaultAccount && getters.defaultAccount.accountId && s.accounts[getters.defaultAccount.accountId] && accountId === getters.defaultAccount.accountId)) &&
            (s.showArchivedAccounts || account.active) &&
            (s.accountsFilters.bankId === null || (s.accountsFilters.bankId === 'nobank' && !s.accounts[account.accountId].bankId) || (s.accountsFilters.bankId === s.accounts[account.accountId].bankId)) &&
            (!s.accountsFilters.accountId || account.accountId === s.accountsFilters.accountId) &&
            (!s.accountsFilters.cardId || getters.cards[s.accountsFilters.cardId].accountId === account.accountId) &&
            (!s.accountsFilters.currencyCode || account.currencyCode === s.accountsFilters.currencyCode) &&
            (!s.accountsFilters.expiringCards || getters.cardsExpire({ expiring: true }).includes(account.accountId)) &&
            (!s.accountsFilters.expiredCards || getters.cardsExpire({ expired: true }).includes(account.accountId)) &&
            (!s.accountsFilters.searchStr || (
              (account.name.toLowerCase().includes(s.accountsFilters.searchStr.toLowerCase()))
              || (account.bankId && getters.banks[account.bankId] && getters.banks[account.bankId].name && getters.banks[account.bankId].name.toLowerCase().includes(s.accountsFilters.searchStr.toLowerCase()))
              || (cardContainsSearchStr(accountId))
              || (
                account.currencyCode
                && (
                  account.currencyCode.toLowerCase().includes(s.accountsFilters.searchStr.toLowerCase())
                  || (Currencies[account.currencyCode] && Currencies[account.currencyCode].symbolUnicode && Currencies[account.currencyCode].symbolUnicode === s.accountsFilters.searchStr)
                  || (Currencies[account.currencyCode] && Currencies[account.currencyCode].locales && currencyNameContainsSearchStr(Currencies[account.currencyCode].locales))
                )
              )
            ))
          )
        })
      }

      if (accountsIds.length > 1) {
        const sortParameter = s.accountsSort.field
        const sortType = s.accountsSort.direction

        if (sortParameter === 'Account' || !sortParameter) {
          accountsIds.sort((a, b) => {
            const accountA = s.accounts[a]
            const accountB = s.accounts[b]

            let nameA = accountA.name ? accountA.name.toLowerCase() : ''
            let nameB = accountB.name ? accountB.name.toLowerCase() : ''

            if (nameA === nameB) {
              if (a < b) { return -1 }
              if (a > b) { return 1 }
              return 0
            } else {
              if (nameA < nameB) { return -1 }
              if (nameA > nameB) { return 1 }
              return 0
            }
          })
        } else if (sortParameter === 'CurrencyCode') {
          accountsIds.sort((a, b) => {
            const accountA = s.accounts[a]
            const accountB = s.accounts[b]

            if (accountA.currencyCode === accountB.currencyCode) {
              if (accountA.name === accountB.name) {
                if (a < b) { return -1 }
                if (a > b) { return 1 }
                return 0
              } else {
                if (accountA.name < accountB.name) { return -1 }
                if (accountA.name > accountB.name) { return 1 }
                return 0
              }
            } else {
              if (accountA.currencyCode < accountB.currencyCode) { return -1 }
              if (accountA.currencyCode > accountB.currencyCode) { return 1 }
              return 0
            }
          })
        } else if (sortParameter === 'Sum') {
          accountsIds.sort((a, b) => {
            const accountA = s.accounts[a]
            const accountB = s.accounts[b]

            if (accountA.sum === accountB.sum) {
              if (accountA.name === accountB.name) {
                if (a < b) { return -1 }
                if (a > b) { return 1 }
                return 0
              } else {
                if (accountA.name < accountB.name) { return -1 }
                if (accountA.name > accountB.name) { return 1 }
                return 0
              }
            } else {
              if (accountA.sum > accountB.sum) { return -1 }
              if (accountA.sum < accountB.sum) { return 1 }
              return 0
            }
          })
        } else if (sortParameter === 'Bank' || !sortParameter) {
          accountsIds.sort((a, b) => {
            const accountA = s.accounts[a]
            const accountB = s.accounts[b]

            let nameA = accountA.name ? accountA.name.toLowerCase() : ''
            let nameB = accountB.name ? accountB.name.toLowerCase() : ''

            let bankAName
            let bankBName

            if (accountA.bankId && getters.banks[accountA.bankId] && getters.banks[accountA.bankId].name) { bankAName = getters.banks[accountA.bankId].name }
            if (accountB.bankId && getters.banks[accountB.bankId] && getters.banks[accountB.bankId].name) { bankBName = getters.banks[accountB.bankId].name }

            if (bankAName === bankBName) {
              if (nameA === nameA) {
                if (a < b) { return -1 }
                if (a > b) { return 1 }
                return 0
              } else {
                if (nameA < nameB) { return -1 }
                if (nameA > nameB) { return 1 }
                return 0
              }
            } else if (bankAName && !bankBName) {
              return -1
            } else if (!bankAName && bankBName) {
              return 1
            } else {
              if (bankAName < bankBName) { return -1 }
              if (bankAName > bankBName) { return 1 }
              return 0
            }
          })
        } else {
          accountsIds.sort((a, b) => {
            const accountA = s.accounts[a]
            const accountB = s.accounts[b]

            let nameA = accountA.name ? accountA.name.toLowerCase() : ''
            let nameB = accountB.name ? accountB.name.toLowerCase() : ''

            if (nameA === nameB) {
              if (a < b) { return -1 }
              if (a > b) { return 1 }
              return 0
            } else {
              if (nameA < nameB) { return -1 }
              if (nameA > nameB) { return 1 }
              return 0
            }
          })
        }

        if (sortType === 'desc') { accountsIds.reverse() }
      }

      return accountsIds
    },
    accountsReset: s => s.accountsReset,
    accountsFilters: s => s.accountsFilters,
    accountsFiltered: s => {
      if (s.accountsFilters.bankId ||
        s.accountsFilters.accountId ||
        s.accountsFilters.cardId ||
        s.accountsFilters.currencyCode ||
        s.accountsFilters.expiringCards ||
        s.accountsFilters.expiredCards ||
        s.accountsFilters.onlyDefault ||
        s.accountsFilters.searchStr
      ) {
        return true
      }
      return false
    },
    accountsShow: s => s.accountsShow,
    showArchivedAccounts: s => s.showArchivedAccounts,
    accountsSort: s => s.accountsSort,
    accountCards: (s, getters) => ({ accountId, active }) => {
      if (getters.accountsReset) {
        //
      }

      if (getters.cardsReset) {
        //
      }

      if (!getters.getLimitNumber('maxCards')) { return [] }

      let defaultCardId

      let cardIds = Object.keys(getters.cards)

      if (cardIds.length && accountId) {
        cardIds = cardIds.filter(cardId => {
          const card = getters.cards[cardId]
          return (card.accountId === accountId && card.active === active)
        })
      }

      if (active && cardIds.length && getters.defaultAccount && getters.defaultAccount.cardId && cardIds.includes(getters.defaultAccount.cardId)) {
        defaultCardId = getters.defaultAccount.cardId
      }

      if (cardIds.length > 1) {
        cardIds.sort((a, b) => {
          const nameA = getters.cards[a].name || a
          const nameB = getters.cards[b].name || b
          if (nameA < nameB) { return -1 }
          if (nameA > nameB) { return 1 }
          return 0
        })
      }

      if (defaultCardId) {
        cardIds.splice(cardIds.indexOf(defaultCardId), 1)
        cardIds.unshift(defaultCardId)
      }

      return cardIds
    },
    debtsByAccountId: (s, getters) => {
      if (getters.transactionsReset) {
        //
      }

      if (getters.accountsReset) {
        //
      }

      let accountIds = Object.keys(s.accounts)

      if (accountIds.length) {
        accountIds = accountIds.filter(accountId => {
          const account = s.accounts[accountId]
          return (account.active && account.sum < 0)
        })
      }

      return accountIds
    },
    debtsByCurrencyCode: (s, getters) => {
      if (getters.transactionsReset) {
        //
      }

      if (getters.accountsReset) {
        //
      }

      let debtsByCurrency = {}

      let allAccountsIds = Object.keys(s.accounts)
      if (allAccountsIds.length) {
        allAccountsIds = allAccountsIds.filter(accountId => {
          const account = s.accounts[accountId]
          return (account.active && account.sum < 0)
        })
      }

      for (const accountId of allAccountsIds) {
        const account = s.accounts[accountId]
        const currencyCode = account.currencyCode

        if (!debtsByCurrency[currencyCode]) {
          debtsByCurrency[currencyCode] = 0
        }

        debtsByCurrency[currencyCode] = debtsByCurrency[currencyCode] + account.sum
      }

      return debtsByCurrency
    },
    shownAccountsData: (s, getters) => {
      if (getters.accountsReset) {
        //
      }

      const answer = {
        accounts: [],
        cards: [],
        showNoBank: false,
        currencies: []
      }

      const allAccounts = Object.keys(s.accounts)

      if (allAccounts.length) {
        for (const accountId of allAccounts) {
          const account = s.accounts[accountId]

          if (!answer.accounts.includes(accountId) && (account.active || s.showArchivedAccounts)) {
            answer.accounts.push(accountId)
          }

          if (getters.getLimitNumber('maxCards')) {
            let cardsArr = Object.keys(getters.cards)
            if (cardsArr.length) {
              cardsArr = cardsArr.filter(cardId => {
                return (getters.cards[cardId].accountId === accountId && getters.cards[cardId].active && (account.active || s.showArchivedAccounts))
              })
            }

            for (const cardId of cardsArr) {
              if (!answer.cards.includes(cardId)) {
                answer.cards.push(cardId)
              }
            }
          }

          if (getters.getLimitNumber('maxBanks')) {
            const bankId = account.bankId
            if (!bankId) { answer.showNoBank = true }
          }

          const currencyCode = account.currencyCode
          if (currencyCode && !answer.currencies.includes(currencyCode)) {
            answer.currencies.push(currencyCode)
          }
        }
      }

      return answer
    },
    totalFilteredAccountsSums: (s, getters) => {
      let currencies = {}

      const filteredAccounts = getters.filteredAccounts
      for (const accountId of filteredAccounts) {
        const account = s.accounts[accountId]
        const currencyCode = account.currencyCode

        if (!currencies[currencyCode]) {
          currencies[currencyCode] = 0
        }

        currencies[currencyCode] = currencies[currencyCode] + account.sum
      }

      return currencies
    },
    accountsFilteredBySections: (s, getters) => {
      return {
        'Account': !!(getters.accountsFilters.bankId || getters.accountsFilters.accountId || getters.accountsFilters.cardId),
        'Currency': !!(getters.accountsFilters.currencyCode),
        'Cards': !!(getters.accountsFilters.expiringCards || getters.accountsFilters.expiredCards),
        'Bank': !!(getters.accountsFilters.bankId)
      }
    }
  }
}