import { mapGetters } from 'vuex'

export default {
  computed: {
    ...mapGetters([
      'filteredTransactions',
      'transactions'
    ])
  },
  watch: {
    clickedTransactionId(transactionId) {
      this.scrollToTransaction(transactionId, false)
    }
  },
  methods: {
    scrollToTransaction(transactionId, toTop) {
      if (transactionId) {
        let headerPannelHeight = 0
        const headerPannel = document.getElementById('HeaderPannel')
        if (headerPannel) { headerPannelHeight = headerPannel.getBoundingClientRect().height }

        const screen = [window.scrollY + headerPannelHeight, window.innerHeight + window.scrollY]

        const transactionElement = document.getElementById('transaction-' + transactionId)
        if (!transactionElement) { return }

        const transactionElementRect = transactionElement.getBoundingClientRect()

        let locationTop = transactionElementRect.top + window.scrollY - headerPannelHeight - 10
        let locationBottom = locationTop + transactionElementRect.height + 35 + headerPannelHeight

        const transaction = this.transactions[transactionId]
        if (transaction && transaction.transferId) {
          const transactionIdx = this.filteredTransactions.findIndex(element => element === transactionId)
          const transferTransactionIdx = this.filteredTransactions.findIndex(element => element === transaction.transferId)

          if (transferTransactionIdx >= 0 && ((transactionIdx + 1 === transferTransactionIdx) || (transactionIdx - 1 === transferTransactionIdx))) {
            const transferTransactionElement = document.getElementById('transaction-' + transaction.transferId)
            if (transferTransactionElement) {
              const transferTransactionElementRect = transferTransactionElement.getBoundingClientRect()

              if (transactionIdx + 1 === transferTransactionIdx) {
                locationBottom = locationTop + transferTransactionElementRect.height + transactionElementRect.height + 35 + headerPannelHeight
              } else if (transactionIdx - 1 === transferTransactionIdx) {
                locationTop = transferTransactionElementRect.top + window.scrollY - headerPannelHeight - 10
                locationBottom = locationTop + transferTransactionElementRect.height + transactionElementRect.height + 35 + headerPannelHeight
              }
            }
          }
        }

        if (locationTop >= screen[0] && locationBottom <= screen[1]) { return }

        let scrollLocation = locationTop
        if (locationTop < screen[0]) {
          scrollLocation = locationTop
        } else if (locationBottom > screen[1] && !toTop) {
          scrollLocation = locationBottom - window.innerHeight
        }

        window.scrollTo({
          left: 0,
          top: scrollLocation,
          behavior: 'smooth'
        })
      }
    }
  }
}